/* eslint-disable @next/next/no-img-element */
import { useEffect, useState, useContext } from "react";
import Link from "next/link";
import Head from "next/head";
import { GlobalContext } from "./globalState";
import { getCoverUrl } from "../utils/cmsUtil";

import OtpLogin from "../components/login/otpLogin";
import LoginStyle from "../styles/Login.module.scss";
import { LanguagePicker } from "../components/app/LanguagePicker";
import { useRouter } from "next/router";

const Index = () => {
  const { t } = useContext(GlobalContext);
  const router = useRouter();

  const [securityPhase, setSecurityPhase] = useState("login");
  const [metaData, setMetaData] = useState(null);

  const onSetLanguage = (language) => {
    localStorage.setItem("language", language);
    router.reload();
  };

  useEffect(() => {
    console.log(localStorage.getItem("language"));

    const metaDataStorage = JSON.parse(localStorage.getItem("meta"));
    setMetaData(metaDataStorage);
  }, []);

  return (
    (!metaData && <div />) || (
      <main id={LoginStyle.main} style={{ direction: localStorage.getItem("language") === "he" ? "rtl" : "ltr" }}>
        <Head>
          <title>GTI Investors Portal</title>
          <link rel="shortcut icon" href="/favicon.svg" />
          <link rel="mask-icon" href="/favicon.svg" color="#000000" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
          <meta name="robots" content="noindex, nofollow" />
        </Head>

        <section id={LoginStyle.leftPane}>
          <div id={LoginStyle.logo}>
            <div style={{ marginTop: "auto" }}>
              <span>
                <img
                  className="mobileHide"
                  alt="GTI"
                  src={!metaData.DesktopLogoLogin ? "/images/gtiLogo.svg" : getCoverUrl(metaData.DesktopLogoLogin)}
                />
                <img
                  className="mobileShow"
                  alt="GTI"
                  src={!metaData.MobileLogoLogin ? "/images/gtiLogo.svg" : getCoverUrl(metaData.MobileLogoLogin)}
                />
              </span>
              <h1>GTI Investors portal</h1>
              <h2 style={{ textAlign: "center" }}>{securityPhase === "login" ? t("login") : t("security-check")}</h2>
            </div>
          </div>

          <OtpLogin metaData={metaData} setSecurityPhase={setSecurityPhase} />
          <div id={LoginStyle.termsFooter}>
            {false && (
              <LanguagePicker
                language={localStorage.getItem("language")}
                onSetLanguage={(lng) => onSetLanguage(lng)}
                dropdownDirection="up"
              />
            )}
            <p>
              {t("by-clicking-the-button-above-you-agree-to-our")}{" "}
              <Link href="/legal/terms-of-use">
                <a>{t("terms-of-use")}</a>
              </Link>{" "}
              {t("and")}{" "}
              <Link href="/legal/privacy">
                <a>{t("privacy-policy")}</a>
              </Link>
              .
            </p>
          </div>
        </section>

        <div id={LoginStyle.bottomLogin}>
          <p>G.T.I. Global Investments Limited</p>
        </div>

        <aside id={LoginStyle.rightPane}>
          <div id={LoginStyle.rightContent}>
            <div id={LoginStyle.loginIcon}>
              <img src="/images/sideLoginIcon.svg" />
            </div>

            <div id={LoginStyle.loginIconText}>
              <h3>Global Investments Limited</h3>
            </div>
          </div>
        </aside>
      </main>
    )
  );
};

export default Index;
